'use client'

import { Dialog } from "@headlessui/react"
import { Incident } from "./AcquiaStatus"
import classNames from "classnames"
import { ReactNode, useState } from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"

export const AcquiaStatusDialog = ({ incidents, maxImpact, children }: {
  incidents: Incident[]
  maxImpact: Incident["impact"]
  children: ReactNode
}) => {

  const [isOpen, setIsOpen] = useState(false)

  if (incidents.length == 0) {
    return <>{children}</>
  }

  return <>
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className={'absolute top-0 left-0 w-full z-50'}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen overflow-y-auto items-center justify-center p-4">
      <Dialog.Panel className="mx-auto max-w-6xl bg-white rounded-lg overflow-hidden">
        <Dialog.Title className={'flex items-center bg-gray-700 text-white font-display text-2xl font-medium p-4'}>
          <span className="flex-grow">Acquia System Status</span>
          <button onClick={() => setIsOpen(false)} className=""><span className="sr-only">Close</span><XMarkIcon className="w-8" /></button>
        </Dialog.Title>
        <Dialog.Description as='div' className={'relative'}>
          {children}
        </Dialog.Description>
      </Dialog.Panel>
      </div>
    </Dialog>

    <button onClick={() => setIsOpen(true)} className={classNames(buttonBgColor(maxImpact), "text-white flex gap-2 p-2 rounded items-center")}>
        More
    </button>
  </>
}

function buttonBgColor(impact: Incident["impact"]) {
  if (impact == "minor") {
    return "bg-yellow-600"
  }
  if (impact == "major") {
    return "bg-orange-600"
  }
  if (impact == "critical") {
    return "bg-pink-800";
  }
  return "bg-teal-600"
}
